import { cloneDeep, forEach } from 'lodash';

import ClassicDS from '../themes/Classic';
import DefaultDS from '../themes/Default';
import DealStyle from './DealStyle';

export const THEMES = [
  {
    themeKey: 'default',
    name: 'Outlaw (default)',
    description: 'Outlaw standard look & feel',
  },
  {
    themeKey: 'classic',
    name: 'Traditional',
    description: 'Times New Roman, 12pt, single spacing',
  },
];

export const DEFAULT_THEME = 'default';

// We can't do a normal merge to combine Deal instance styles with theme styles,
// because doing so deeply combines property values, when what we want is replacement by key/index
export function mergeInstanceDS(themeDS, instanceDS = {}) {
  let merged = cloneDeep(themeDS);

  forEach(instanceDS.layout, (ls, key) => {
    merged.layout[key] = ls;
  });

  forEach(instanceDS.numbering, (ns, idx) => {
    merged.numbering[idx] = ns;
  });

  forEach(instanceDS.type, (ts, key) => {
    merged.type[key] = ts;
  });

  console.log('styleFactory merged', merged);

  return merged;
}

export default class StyleFactory {
  static create(themeKey, themeDS, instanceDS) {
    let dealStyle = null;

    if (themeKey && themeKey.includes(':')) {
      console.log('styleFactory', { themeDS, instanceDS, themeKey });
      dealStyle = new DealStyle(mergeInstanceDS(themeDS, instanceDS), themeKey);
    } else {
      switch (themeKey) {
        case 'classic':
          dealStyle = new DealStyle(mergeInstanceDS(ClassicDS, instanceDS), themeKey);
          break;
        case 'default':
        default:
          dealStyle = new DealStyle(mergeInstanceDS(DefaultDS, instanceDS), themeKey);
          break;
      }
    }

    const errors = dealStyle.validate();
    if (errors.length > 0) {
      // forEach(errors, console.error);
      dealStyle = StyleFactory.create(DEFAULT_THEME, null, instanceDS);
    }

    // Automatically explicitly set line spacing in all styles for anything that is NOT defined in theme or custom
    // Still WIP, leave for now
    /*
    if (theme.spacingRatio > 0) {
      forEach(theme.type, (typeDef, key) => {
        const spacing = get(typeDef, 'css.lineHeight', null);
        let fontSize = get(typeDef, 'css.fontSize', null);
        if (fontSize && typeof(fontSize) === 'string') {
          fontSize = Number.parseInt(fontSize.replace('px', ''));
        }
        if (spacing && !isNaN(fontSize) && !get(typeDef.docx, 'paragraph.spacing.line', null)) {
          typeDef.docx.paragraph = merge(typeDef.docx.paragraph, {
            spacing: { 
              line: spacing * fontSize * 15 * theme.spacingRatio
            } 
          });
          console.log(key, typeDef.docx.paragraph);
          // TODO: pdf also
        }
      });
    }
    */

    // Leave commented, but this is useful for printing the full list of type styles defined in the theme
    // console.log(map(theme.type, (val, key) => `'${key}'`).join(',\n'));

    return dealStyle;
  }
}

import { observer } from 'mobx-react';
import React, { Component, createRef, useState } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Timeline } from '@anderson-fv/vis-timeline/standalone';
import '@anderson-fv/vis-timeline/styles/vis-timeline-graph2d.css';
import autoBindMethods from 'class-autobind-decorator';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import { autorun, makeAutoObservable } from 'mobx';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';

import { ButtonGroup, FormControl, Modal, ProgressBar } from 'react-bootstrap';

import TimelineEvent, { EVENT_TYPES, TimelineEventStatus } from '@core/models/TimelineEvent';
import { ValueType, VariableType } from '@core/models/Variable';
import { getSafeKey, getUniqueKey } from '@core/utils';
import { generateExportURL } from '@core/utils';
import DateFormatter from '@core/utils/DateFormatter';
import { base64ToArray } from '@core/utils/Generators';

import { Button, ButtonClose, ButtonIcon, Icon, Swatch } from '@components/dmp';

import TableView from '@components/TableView';
import TimelineFilter from '@components/TimelineFilter';
import { measure } from '@components/section_types/SectionMeasurer';
import API from '@root/ApiClient';
import Fire from '@root/Fire';
import trackEvent from '@utils/EventTracking';

//
// TimelineView component is based around the open source vis-timeline.
//  Examples: https://visjs.github.io/vis-timeline/examples/timeline/
//      Docs: https://visjs.github.io/vis-timeline/docs/timeline/
//

const TL_HEIGHT = 8.5 * 96 - 2 * 96;
const TL_WIDTH = 11 * 96 - 2 * 96;

const getPaddedRange = (start, end) => {
  const diffTime = Math.abs(end - start);

  // TODO: Box width is set in CSS, so either need to manually keep these
  // in sync, or do something more clever? Currently not in sync, so events
  // don't get framed perfectly right now.

  // 1 box = 200px width; full width is 864
  // so we need to add 200/864 to get to the right side of the last box
  // plus 20/864 on each side to get a 20px margin
  const boxWidthMS = diffTime * (275 / TL_WIDTH); // TODO: where did 275 come from?
  const marginMS = diffTime * (20 / TL_WIDTH);
  const newEndDate = new Date().setTime(end.getTime() + boxWidthMS + marginMS);
  const newStartDate = new Date().setTime(start.getTime() - marginMS);
  return [newStartDate, newEndDate];
};

function getTimelineVarName(deal) {
  const section = deal.timeline;
  return 'TL-' + getSafeKey(section.name, false, true);
}

function getTimelineVarData(deal) {
  const tlVar = deal.variables[getTimelineVarName(deal)];
  return tlVar?.tableValueFormatted || [];
}

class TimelineStore {
  selectedItemID = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  selectItem(itemID) {
    this.selectedItemID = itemID;
  }
  clearSelection() {
    this.selectItem(undefined);
  }
}

@autoBindMethods
export default class TimelineView extends Component {
  constructor(props) {
    super(props);
    this.childRefs = {};
    this.state = {
      editingPrompt: false,
      aiPrompt: '',
      exportProgress: null,
      itemData: [],
    };
    this.sectionRefs = {};
    this.refSelf = createRef();
    this.refFilters = createRef();

    this.timeline = null;

    this.store = new TimelineStore();
    this.disposer = null;
  }

  async resetTimeline() {
    const { deal } = this.props;
    const tlVar = deal.variables[getTimelineVarName(deal)];
    const section = deal.timeline;

    // TODO: delete table var also

    await Fire.saveSection(section, { content: null });
    await Fire.saveVariable(deal, tlVar, null);

    if (this.timeline) {
      this.timeline.destroy();
      this.timeline = null;
    }
  }

  componentDidMount() {
    this.disposer = autorun(() => {
      if (this.store.selectedItemID === undefined && this.timeline) {
        this.timeline.setSelection([]);
      }
    });

    if (this.getSourceData().length) {
      this.renderTimeline();
    }
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  componentDidUpdate(prevProps) {
    measure(this);

    if (this.getSourceData().length && !this.timeline) {
      this.renderTimeline();
      measure(this);
    }
  }

  async toggleEditingPrompt() {
    const { deal } = this.props;
    const section = deal.timeline;
    const { aiPrompt } = section;
    const { prompt } = aiPrompt;

    const txtPrompt = _.get(prompt, '[0].content', '');

    this.setState({
      editingPrompt: true,
      aiPrompt: txtPrompt,
    });
  }

  async savePrompt() {
    const { aiPrompt } = this.state;
    const { deal } = this.props;
    const section = deal.timeline;

    const json = section.aiPrompt.json;

    // This is awful and must be fixed to not hardcode
    json.prompt[0].content = aiPrompt;
    console.log(json);

    // Close the modal
    await this.setState({ editingPrompt: false });

    // Save the prompt in the section config data
    await Fire.saveSection(section, { aiPrompt: json });

    // Calling reset clears existing data (section content and variable) and triggers generation
    await this.resetTimeline();
  }

  focusOnItem(itemId) {
    // TODO: this kinda works, but the item isn't truly centered, but it should be "good enough"
    // to at least ensure it is nicely visible (not hidden by Event Detail panel).
    this.timeline.focus(itemId, {
      zoom: false,
      animation: {
        duration: 400,
        easingFunction: 'easeInOutQuad',
      },
      offset: 0.3, //TODO: tweak this as needed
    });
  }

  renderTimeline() {
    // Only generate a new timeline the first time
    // subsequent updates can use the existing instance
    if (!this.timeline) {
      const el = document.getElementById(this.timelineDivID);
      this.timeline = new Timeline(el);

      this.timeline.on('select', (tl_props) => {
        const itemId = tl_props.items[0];
        this.store.selectItem(itemId);
        this.focusOnItem(itemId);
      });

      // We inject a special Overlay component at just the right spot in the timeline's DOM so that we can
      // use it to 'gray out' the unselected event boxes, but keep the selected one full opacity.
      const visGroup = document.querySelector('.vis-foreground > .vis-group');
      if (visGroup) {
        ReactDOM.render(<EventSelectionOverlay timelineStore={this.store} />, visGroup);
      }
    }

    const itemTemplate = (item, element) => {
      if (!item) {
        return;
      }

      // Although the vis-timeline docs suggest using a Portal here, I'm instead going with a simpler
      // approach mentioned here: https://github.com/visjs/vis-timeline/issues/1211
      // Note that, changes to these props will not trigger these components to re-render
      // as they are not in the "normal" virtual dom hierarchy. However, this is fully mitigated by
      // the use of mobx for any changing state.
      ReactDOM.render(<EventContent item={item} />, element);
      return undefined;
    };

    // TODO: When using a react component as the item template, we have to manually call timeline.redraw()
    // or the items aren't positioned properly. Further, on first render they still appear wrong, but as soon
    // as you start panning around they "snap" into their correct positions.
    // For now I'm forcing this "proper" re-render by calling .zoomOut(). Earlier I tried to use .fit()
    // but it annoyingly results in a horizontal offset to the right for some unknown reason.
    setTimeout(() => {
      this.timeline.redraw();
      this.timeline.zoomOut(0.001);
    }, 1);

    // Fixed landscape dimensions with one inch margins
    const height = TL_HEIGHT;
    const width = TL_WIDTH;

    const opts = {
      width,
      height,
      align: 'left',
      margin: {
        item: 5,
      },
      orientation: {
        axis: 'top',
        item: 'top',
      },
      order: function customOrder(a, b) {
        return a.order - b.order;
      },
      showTooltips: false,
      template: itemTemplate,
      zoomFriction: 50,
    };

    // Compute the 'itemData' by converting sourceData into the correct format for
    // vis-timeline to consume (note that we 'cache' this itemData as state so that user edits
    // from the event details panel are easier to apply):
    const sourceData = this.getSourceData();
    let itemData;
    {
      itemData = [];
      sourceData.forEach((event, index) => {
        // Add tz offset like " GMT-400" to ensure that dates aren't changed
        const date = DateFormatter.localizeUTC(new Date(event.date));
        itemData.push({
          start: date,
          // TODO: which DateFormatter method do we want to use?
          //content: `<div><span>${DateFormatter.mdy(date)}</span>${evt.summary}</div>`,
          content: `${event.summary}`,
          title: DateFormatter.locale(date, 'en-US'),
          id: event.id,
          limitSize: true,
          align: 'left',
          className: event.type,
          order: index, // See: customOrder function above
          sourceID: event.source,
        });
      });
      this.setState({ itemData });
    }

    // TODO: show some kind of info/error message on timeline if there are no events found?
    // (or if all of them are filtered out?)
    if (itemData.length > 0) {
      const [start, end] = getPaddedRange(_.first(itemData).start, _.last(itemData).start);
      opts.start = start;
      opts.end = end;
    }
    this.timeline.setOptions(opts);
    this.timeline.setItems(itemData);
  }

  async buildPDF() {
    const { deal } = this.props;

    let currentStep = 1;
    const totalSteps = 2 + deal.attachedFiles.length;

    // 1. Start with export of prose doc
    // this will fetch the generated doc into memory for further editing/compilation
    await this.setState({ exportProgress: { currentStep, totalSteps, description: 'Exporting main document...' } });
    const token = await Fire.token();
    const url = generateExportURL({ deal, token });
    const bufferDoc = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(bufferDoc);

    // 2. Add the Timeline export
    currentStep++;
    await this.setState({ exportProgress: { currentStep, totalSteps, description: 'Adding Timeline...' } });
    const el = document.getElementById(this.timelineDivID);
    const canvas = await html2canvas(el);
    const dataURL = canvas.toDataURL('image/png', 1);
    const b64 = dataURL.replace('data:image/png;base64,', '');
    const bytes = base64ToArray(b64);

    const pngImage = await pdfDoc.embedPng(bytes);
    const timelinePage = pdfDoc.addPage();
    timelinePage.setSize(11 * 96, 8.5 * 96);

    const [pw, ph] = [timelinePage.getWidth(), timelinePage.getHeight()];
    const [scaledWidth, scaledHeight] = [pngImage.width / 2, pngImage.height / 2];

    timelinePage.drawImage(pngImage, {
      x: (pw - scaledWidth) / 2,
      y: (ph - scaledHeight) / 2,
      width: scaledWidth,
      height: scaledHeight,
    });

    // 3. loop through attachments and load all, pulling out target pages of each
    let attachments = deal.attachedFiles;
    attachments = _.sortBy(attachments, (att) => att.title.toLowerCase());

    for (let i = 0; i < attachments.length; i++) {
      const attachment = attachments[i];

      // Temporarily using the Attachment.description field to designate array of target pages
      // this needs to be converted into real numbers, and also accommodate for a zero-based array
      const pages = _.map(attachment.description.split(','), (page) => parseInt(page) - 1);
      console.log(pages);

      currentStep++;
      await this.setState({
        exportProgress: {
          currentStep,
          totalSteps,
          description: `Attaching ${pages.length} pages from ${attachment.title}...`,
        },
      });
      // Load a PDFDocument in memory from each of the existing PDFs
      const downloadURL = await Fire.storage.ref(attachment.bucketPath).getDownloadURL();
      const buffer = await fetch(downloadURL).then((res) => res.arrayBuffer());
      const excerptPDF = await PDFDocument.load(buffer);
      // console.log('Excerpt', excerptPDF);

      // Copy pages from the doc
      const excerptPages = await pdfDoc.copyPages(excerptPDF, pages);
      _.forEach(excerptPages, (page) => pdfDoc.addPage(page));
    }

    // TODO: for adding internal links to target pages in doc
    // https://github.com/Hopding/pdf-lib/issues/123#issuecomment-568804606

    const pdfBytes = await pdfDoc.save();
    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

    // Create an anchor, and set the href value to our data URL
    const createEl = document.createElement('a');
    // createEl.href = dataURL;
    createEl.href = pdfDataUri;

    // This is the name of our downloaded file
    createEl.download = deal.info.name;

    // Click the download button, causing a download, and then remove it
    createEl.click();
    createEl.remove();

    // Close modal
    this.setState({ exportProgress: null });
  }

  renderExportProgress() {
    const { exportProgress } = this.state;

    const percent = (exportProgress.currentStep / exportProgress.totalSteps) * 100;

    return (
      <Modal dialogClassName="pdf-compiler" show={true} onHide={_.noop} data-cy="pdf-compiler">
        <Modal.Header closeButton={false}>
          <span className="headline">Compiling PDF</span>
        </Modal.Header>

        <Modal.Body>
          <div className="saving">
            <ProgressBar bsStyle="info" now={percent} />
            <div className="details">
              <div className="step">
                {exportProgress.currentStep} of {exportProgress.totalSteps}
              </div>
              <div className="description">{exportProgress.description}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  get timelineDivID() {
    const { deal } = this.props;
    const section = deal.timeline;
    return `ai-timeline-${section.id}`;
  }

  getSourceData({ filtered } = { filtered: true }) {
    const { deal } = this.props;
    let data = getTimelineVarData(deal);

    if (filtered) {
      // TODO: don't use this ref hack, instead lift the state up.
      const activeTypes = this.refFilters?.current?.state.activeTypes;

      if (activeTypes) {
        data = _.filter(data, (evt) => activeTypes.includes(evt.type));
      }
    }

    return data;
  }

  // Prevent mouse events from taking whole section into editing when invoked in Flow (ContentSection)
  stop(e) {
    e.stopPropagation();
  }

  renderPromptEditor() {
    const { aiPrompt } = this.state;

    return (
      <Modal
        dialogClassName="timeline-prompt-editor"
        show={true}
        onHide={() => this.setState({ editingPrompt: false })}
        onMouseDown={this.stop}
        onMouseUp={this.stop}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <span className="headline">Edit Timeline Prompt</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            <FormControl
              componentClass="textarea"
              className="txt-prompt"
              value={aiPrompt}
              onChange={(e) => this.setState({ aiPrompt: e.target.value })}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.savePrompt}>Generate</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { deal, user } = this.props;
    const timeline = deal.timeline;
    const { editingPrompt, exportProgress } = this.state;

    // TODO: possibly change to allow updating of summary, date, type?
    const itemUpdater = async ({ id, newText }) => {
      newText = newText.trim();

      // First we update the variable and persist it:
      const tlVar = deal.variables[getTimelineVarName(deal)];
      const val = JSON.parse(tlVar.value);
      const editRow = val.find((x) => x.id === id);
      if (!editRow) {
        console.error('Failed to find editRow!');
        return; // TOOD: how to handle error?
      }
      editRow.summary = newText;
      editRow.status = TimelineEventStatus.human;
      await Fire.saveVariable(deal, tlVar, JSON.stringify(val));

      // Then we update the timeline item data:
      const itemData = this.state.itemData;
      const item = itemData.find((x) => x.id === id);
      if (!item) {
        console.error('Failed to find item!');
        return; // TOOD: how to handle error?
      }
      item.content = newText;
      item.status = TimelineEventStatus.human;
      this.timeline.setItems(itemData);
      this.setState({ itemData });

      // Have to re-select item or it will appear grayed-out, and also have to re-focus in case
      // it jumped to a new location due to reflow/restacking:
      this.timeline.setSelection([id]);
      this.focusOnItem(id);
    };

    const allEvents = this.getSourceData({ filtered: false });
    const hasEvents = allEvents.length > 0;

    return (
      <div className="timeline-view" data-cy="timeline-view" ref={this.refSelf}>
        {hasEvents && (
          <div className="timeline-actions">
            <Button size="small" data-cy="btnCompilePDF" onClick={this.buildPDF}>
              Compile PDF
            </Button>
          </div>
        )}

        <div id={this.timelineDivID} />

        {hasEvents && <TimelineFilter ref={this.refFilters} onChange={this.renderTimeline} events={allEvents} />}

        {editingPrompt && this.renderPromptEditor()}
        {exportProgress && this.renderExportProgress()}

        <EventBlockerOverlay timelineStore={this.store} />

        <div className="timeline-cropper">
          <EventDetailPanel
            timelineStore={this.store}
            sourceData={this.getSourceData()}
            itemUpdater={itemUpdater}
            deal={this.props.deal}
          />
        </div>

        <TimelineGenerator deal={deal} user={user} />
      </div>
    );
  }
}

const EventDetailPanel = observer(({ timelineStore, sourceData, itemUpdater, deal }) => {
  const tabs = [
    {
      key: 'summary',
      text: 'Summary',
    },
    // TODO: crashes when source data is coming from FV medchron collection...
    // {
    //   key: 'source',
    //   text: 'Prose Source',
    // },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].key);

  useEffect(() => {
    return autorun(() => {
      if (timelineStore.selectedItemID) {
        setCurrentTab(tabs[0].key);
      }
    });
  }, []);

  const id = timelineStore.selectedItemID;
  const data = _.isNil(id) ? null : sourceData.find((x) => x.id === id);

  const handleClose = () => {
    timelineStore.clearSelection();
  };

  return (
    <div className={`timeline-event-detail-panel ${data ? 'visible' : ''}`}>
      <div className="title-row">
        <div className="title">Event detail</div>
        <ButtonClose onClick={handleClose} />
      </div>

      <div className="tabs-row">
        <ButtonGroup className="panel-tabs">
          {tabs.map((tab) => (
            <Button
              key={tab.key}
              dmpStyle="link"
              active={currentTab === tab.key}
              onClick={() => setCurrentTab(tab.key)}
            >
              {tab.text}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      {currentTab === 'summary' && data ? <EventDetailPanelSummary data={data} itemUpdater={itemUpdater} /> : null}
      {currentTab === 'source' && data ? <EventDetailPanelSource data={data} deal={deal} /> : null}
    </div>
  );
});

function getStatusIconName(status) {
  return status === TimelineEventStatus.ai ? 'aiAuto' : 'fieldsEdit';
}
function getStatusDescription(status) {
  return status === TimelineEventStatus.ai ? 'AI-generated' : 'Human-edited';
}

const EventDetailPanelSummary = ({ data, itemUpdater }) => {
  const [editing, setEditing] = useState(false);
  const [editedText, setEditedText] = useState('');

  const handleEdit = () => {
    setEditing(true);
    setEditedText(data.summary);
  };
  const handleCancel = () => {
    setEditing(false);
  };
  const handleSave = async () => {
    setEditing(false);
    itemUpdater({ id: data.id, newText: editedText });
  };
  const handleChange = (e) => {
    setEditedText(e.target.value);
  };

  const formatDate = (date_str) => {
    return moment(date_str).format('LL'); // eg. January 31, 2020
  };
  const eventType = EVENT_TYPES[data.type];

  return (
    <>
      <div className="details-row">
        <div className="event-date">{formatDate(data.date)}</div>
        <div className="event-type">
          <Swatch color={eventType.color} />
          <span>{eventType.title}</span>
        </div>
      </div>

      {editing ? (
        <div className="summary-editor">
          <FormControl
            componentClass="textarea"
            value={editedText}
            placeholder="Enter the event summary text"
            onChange={(e) => handleChange(e)}
          />
        </div>
      ) : (
        <div className="summary">{data.summary}</div>
      )}

      {editing ? (
        <div className="controls-editing">
          <Button dmpStyle="link" size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button size="small" onClick={handleSave}>
            Save
          </Button>
        </div>
      ) : (
        <div className="controls-viewing">
          <div className="status">
            <Icon name={getStatusIconName(data.status)} />
            <span>{getStatusDescription(data.status)}</span>
          </div>
          {/* TODO: not the correct "pencil" icon... see Figma */}
          <ButtonIcon icon="deal" onClick={handleEdit}></ButtonIcon>
        </div>
      )}
    </>
  );
};

const EventDetailPanelSource = ({ data, deal }) => {
  const getSourceText = (sourceID, deal) => {
    const linkedSectionIDs = deal.timeline.aiPrompt.linkedSections;
    const linkedSections = linkedSectionIDs.map((id) => deal.sections[id]);
    const sourceItems = linkedSections[0].items; //TODO: ugly hardcoded index
    const targetSection = sourceItems.find((item) => item.id === sourceID);

    if (targetSection) {
      const text = targetSection.currentVersion.getText('body', true, deal.variables);
      return text;
    }
    console.error('Failed to find source item:', sourceID);
    return '';
  };

  return (
    <>
      <div className="content">{getSourceText(data.source, deal)}</div>
    </>
  );
};

export function TimelineEventEditor(props) {
  const { deal, user } = props;

  const tlVar = deal.variables[getTimelineVarName(deal)];

  function fixRow(rowData) {
    // Each Event needs a unique ID, we generate these when receiving events back from the AI, and we
    // must also set them here when user adds a manual new event:
    if (!rowData.id) {
      rowData.id = getUniqueKey();
    }

    // NOTE: If the user creates a new row in the Event Data table, and they don't explicitly set the event type,
    // then it will default to an empty string. However, visually they will have seen the first option in the select
    // show up (right now you see 'Death' because it comes up first alphabetically). So in this case, we grab the
    // array of event types, sort them lexically, and then just use the first one. AKA: implement a default selection.
    if (rowData.type === '') {
      rowData.type = Object.values(EVENT_TYPES).toSorted((a, b) => a.title.localeCompare(b.title))[0].key;
    }

    // Obviously the user is editing the data, so we set status to 'human':
    rowData.status = TimelineEventStatus.human;
  }

  async function regenerateEvents() {
    const section = deal.timeline;
    await Fire.saveSection(section, { content: null });
    await Fire.saveVariable(deal, tlVar, null);
  }

  const colRenderers = {
    status: (val) => {
      return <Icon name={getStatusIconName(val)} />;
    },
  };

  return (
    <>
      <TimelineGenerator deal={deal} user={user} />
      {!!tlVar && (
        <div style={{ padding: '30px' }}>
          <TableView
            section={deal.timeline}
            variable={tlVar}
            beforeSaveRowEdit={fixRow}
            customColumnRenderers={colRenderers}
          />
          <Button onClick={regenerateEvents}>Regenerate Events</Button>
        </div>
      )}
    </>
  );
}

const EventContent = observer((props) => {
  const { item } = props;
  const typeLabel = EVENT_TYPES[item.className].title;
  return (
    <>
      <div className="tl-item-info-row">
        <div>{item.title}</div>
        <div>·</div>
        <div className="tl-item-type">{typeLabel}</div>
      </div>
      <div className="tl-item-content">{item.content}</div>
    </>
  );
});

const EventSelectionOverlay = observer((props) => {
  const { timelineStore } = props;
  const selected = Boolean(timelineStore.selectedItemID);
  return <div className={`timeline-selection-overlay ${selected ? 'active' : 'inactive'}`}></div>;
});

const EventBlockerOverlay = observer(({ timelineStore }) => {
  const handleClick = () => {
    timelineStore.clearSelection();
  };

  return timelineStore.selectedItemID === undefined ? null : (
    <div className="timeline-event-blocker-overlay" onClick={handleClick}></div>
  );
});

export function TimelineGenerator(props) {
  const { deal, user } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const genIfNeeded = async () => {
      if (shouldGenerate()) {
        setLoading(true);
        await generate(deal);
        setLoading(false);
      }
    };
    genIfNeeded();
  }, [deal.timeline]);

  const shouldGenerate = () => {
    const section = deal.timeline;
    const source = section.aiPrompt?.dataSourceAI;
    const data = getTimelineVarData(deal);
    if (section.isTimeline && !loading && source && !data.length) {
      return true;
    }
    return false;
  };

  const generate = async () => {
    const section = deal.timeline;
    const { aiPrompt } = section;
    const { engine, prompt } = aiPrompt;

    console.log('Generating timeline events with prompt:', prompt);

    const source = aiPrompt.timelineSourceAI;
    let skynet = await API.call('summarize', { aiPrompt: aiPrompt.json, source, dealID: deal.dealID, json: true });

    console.log('AI RESPONSE:', skynet);

    if (!skynet?.result?.[0]) {
      // TODO: how to properly handle this error?
      // TODO: Set "error" state and display error message to user somehow.
      return;
    }

    const timelineEvents = [];
    for (const result of skynet.result) {
      const event = new TimelineEvent({
        id: getUniqueKey(),
        ...result,
      });
      timelineEvents.push(event);
    }

    const varDef = {
      name: getTimelineVarName(deal),
      type: VariableType.SIMPLE,
      valueType: ValueType.TABLE,
      value: JSON.stringify(timelineEvents),
      columns: [
        {
          id: 'status',
          displayName: 'Status',
          valueType: ValueType.STRING,
          editable: false,
          width: 1,
        },
        {
          id: 'date',
          displayName: 'Date',
          valueType: ValueType.DATE,
          editable: true,
          width: 2, // TODO: would be nice to go to width 1, but have the text non-line-breaking...
        },
        {
          id: 'type',
          displayName: 'Type',
          valueType: ValueType.SELECT,
          property: EVENT_TYPES,
          editable: true,
          width: 2,
        },
        {
          id: 'summary',
          displayName: 'Summary',
          valueType: ValueType.STRING,
          editable: true,
          multiline: true, //TODO: This doesn't currently work!
          width: 4,
        },
      ],
    };

    await Fire.saveSection(section, { content: `[#${getTimelineVarName(deal)}]` });
    await Fire.saveVariableDefinition(deal, varDef);

    const eventData = {
      serviceType: `${_.upperFirst(aiPrompt.type)} AI Block Preview`, //should eventually be enumerated somewhere for additional types
      docID: deal.dealID,
      user: user.email,
      teamID: deal.team,
      engine: engine.key,
      isTemplate: deal.isTemplate,
      template: deal.info.sourceTemplate,
    };
    await trackEvent('TimelineSectionGenerate', eventData);
  };

  // TODO: should close button cancel the generation somehow?
  return loading ? (
    <Modal show={true} backdrop="static">
      <Modal.Header closeButton>
        <span className="headline">Generating Timeline Events</span>
      </Modal.Header>

      <Modal.Body>
        <div className="wrapper">
          <div className="summarizing">This may take up to 30 seconds...</div>
        </div>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  ) : null;
}

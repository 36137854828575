import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { PDFDocument, StandardFonts } from 'pdf-lib';
import PropTypes from 'prop-types';

import { autoTimescale, buildLayout, buildTapeLayout, zoomToFit } from '@root/shared/TimevineShared';

//-------------------------------------------------------------------------------------------------
// <Text> COMPONENT
//-------------------------------------------------------------------------------------------------
const Text_propTypes = {
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  bold: PropTypes.bool,
};
const Text = ({ text, fontSize, color, bold }) => {
  return (
    <text
      dominantBaseline={'hanging'}
      textAnchor="begin"
      pointerEvents={'none'}
      style={{
        fontSize: String(fontSize),
        fill: color,
        fontWeight: bold ? 'bold' : undefined,
      }}
    >
      {text}
    </text>
  );
};
Text.propTypes = Text_propTypes;

//-------------------------------------------------------------------------------------------------
// <EventBox> COMPONENT
//-------------------------------------------------------------------------------------------------
const EventBox_propTypes = {
  box: PropTypes.object.isRequired,
  faded: PropTypes.bool,
  onClicked: PropTypes.func.isRequired,
};
function EventBox({ box, faded, onClicked }) {
  const handlePointerUp = () => {
    onClicked(box.event);
  };

  return (
    <g transform={`translate(${box.x}, ${box.y})`} onPointerUp={handlePointerUp} style={{ cursor: 'pointer' }}>
      <rect
        x={0}
        y={0}
        width={box.width}
        height={box.height}
        style={{
          fill: box.fillColor,
          stroke: faded ? 'hsla(210, 0%, 50%, 0.5)' : box.color,
        }}
        rx={0}
        ry={0}
      />
      <g transform={`translate(${box.horizPad}, ${box.vertPad})`}>
        <Text text={box.dateText} fontSize={10} color={'black'} bold />
      </g>
      {/* TODO: Don't hard-code this offset, have to compute in buildLayout! */}
      <g transform={`translate(${box.horizPad + 100}, ${box.vertPad})`}>
        <Text text={box.typeText} fontSize={10} color={box.color} bold />
      </g>
      <g transform={`translate(${box.horizPad}, ${box.vertPad + box.summaryTextYOffset})`}>
        <rect x={0} y={0} width={box.textWidth} height={box.textHeight} style={{ fill: 'transparent' }} />
        {box.summaryTextLines.map((line, index) => (
          <g key={line + index} transform={`translate(0, ${index * box.summaryTextLineSpacing})`}>
            <Text text={line} fontSize={12} color={'rgb(90, 101, 115)'} />
          </g>
        ))}
      </g>

      {faded && (
        <rect
          x={0}
          y={0}
          width={box.width}
          height={box.height}
          style={{ fill: 'rgba(255,255,255,0.8)', cursor: 'pointer' }}
        ></rect>
      )}
    </g>
  );
}
EventBox.propTypes = EventBox_propTypes;

//-------------------------------------------------------------------------------------------------
// <Timevine> COMPONENT
//-------------------------------------------------------------------------------------------------
const Timevine_propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  events: PropTypes.array,
  autoFitOnLoad: PropTypes.bool,
  onEventSelected: PropTypes.func,
  onCameraChanged: PropTypes.func,
  onTimescaleChanged: PropTypes.func,
};
const Timevine = forwardRef(
  ({ width, height, events, autoFitOnLoad, onEventSelected, onCameraChanged, onTimescaleChanged }, ref) => {
    const tapeThickness = 50;
    const diagramPadding = 20;

    const defaultCamera = Object.freeze({ x: -diagramPadding, y: -diagramPadding - tapeThickness, scale: 1.0 });
    const zoomSpeed = 0.05;
    const dragThreshold = 5.0;

    const [font, setFont] = useState(null);

    const [layout, setLayout] = useState(null);
    const [timescale, setTimescale] = useState(0.4);
    const [camera, setCamera] = useState(defaultCamera);

    const [autoFitOnLoadDone, setAutoFitOnLoadDone] = useState(false);

    const [dragging, setDragging] = useState(false);
    const [panning, setPanning] = useState(false);
    const [dragCursorStartPos, setDragCursorStartPos] = useState({
      x: 0,
      y: 0,
    });
    const [dragCameraStartPos, setDragCameraStartPos] = useState({
      x: 0,
      y: 0,
    });

    const [selectedEvent, setSelectedEvent] = useState(null);

    const svgRef = useRef();

    // When events or timescale changes, (re)compute the layout:
    useEffect(() => {
      const doLayout = (_font) => {
        const newLayout = buildLayout(events, timescale, (text, fontSize) => {
          return _font.widthOfTextAtSize(text, fontSize);
        });
        setLayout(newLayout);
      };

      if (font) {
        doLayout(font);
      } else {
        // Creating a font is expensive, so we cache it. If we don't do this we see terrible
        // performance for things like dragging the timescale.
        createFontHelvetica().then((font) => {
          doLayout(font);
          setFont(font);
        });
      }
    }, [events, timescale]);

    // If autoFitOnLoad is true, then do the autoTimescale and then zoomToFit when it's
    // valid to do so but only once:
    useEffect(() => {
      if (!autoFitOnLoad) return;
      if (autoFitOnLoadDone) return;
      if (!layout) return;
      if (width <= 0) return;
      if (height <= 0) return;

      setAutoFitOnLoadDone(true);

      _autoTimescale((newLayout) => {
        _zoomToFit(newLayout);
      });
    }, [layout, width, height]);

    // Pass state up to parent:
    useEffect(() => {
      if (onEventSelected) onEventSelected(selectedEvent);
    }, [selectedEvent]);

    useEffect(() => {
      if (onCameraChanged) onCameraChanged(camera);
    }, [camera]);

    useEffect(() => {
      if (onTimescaleChanged) onTimescaleChanged(timescale);
    }, [timescale]);

    // We have to manually manage 'wheel' event listener because React will set it up
    // as passive, which prevents us from using preventDefault().
    useEffect(() => {
      const svg = svgRef.current;
      if (!svg) return;
      svg.addEventListener('wheel', handleWheel, { passive: false });
      return () => {
        svg.removeEventListener('wheel', handleWheel);
      };
    }, []);

    // Imperative Methods:
    const resetCamera = () => {
      setCamera(defaultCamera);
    };

    const _autoTimescale = (done) => {
      if (width <= 0) {
        console.error('_autoTimescale: width not valid:', width);
        return;
      }
      if (height <= 0) {
        console.error('_autoTimescale: height not valid:', height);
        return;
      }
      if (!layout) {
        console.error('_autoTimescale: layout not valid');
        return;
      }

      // TODO: Need to account for the tape height and possibly padding when computing this...
      const targetAspectRatio = width / height;

      createFontHelvetica().then((font) => {
        const funcMeasureTextWidth = (text, fontSize) => {
          return font.widthOfTextAtSize(text, fontSize);
        };
        const newLayout = autoTimescale({ layout, targetAspectRatio, events, funcMeasureTextWidth });
        setTimescale(newLayout.timescale);

        if (done) {
          done(newLayout);
        }
      });
    };

    const _zoomToFit = (layout) => {
      const doublePad = diagramPadding * 2;
      const _width = width - doublePad;
      const _height = height - doublePad - tapeThickness;

      const newScale = zoomToFit({ layout, width: _width, height: _height });

      setCamera({
        x: -diagramPadding,
        y: -diagramPadding - tapeThickness,
        scale: newScale,
      });
    };

    const clearSelectedEvent = () => {
      setSelectedEvent(null);
    };

    useImperativeHandle(ref, () => ({
      resetCamera,
      autoTimescale: _autoTimescale,
      zoomToFit: _zoomToFit.bind(undefined, layout),
      clearSelectedEvent,
    }));

    const handleWheel = (evt) => {
      evt.preventDefault();

      const svg = svgRef.current;
      if (!svg) return;
      const rect = svg.getBoundingClientRect();
      const { clientX, clientY } = evt;
      const ptrX = clientX - rect.left;
      const ptrY = clientY - rect.top;

      setCamera((prevCamera) => {
        const oldScale = prevCamera.scale;

        let newScale = oldScale + (evt.deltaY < 0 ? zoomSpeed : -zoomSpeed);
        newScale = clamp(newScale, 0.25, 3); // Impose reasonable limits

        // Compute delta offset for translation that results in zooming into (or out of) the
        // pointer position (instead of the origin):
        let ptrXWorld = ptrX + prevCamera.x;
        let ptrXScaledOld = ptrXWorld * oldScale;
        let ptrXScaledNew = ptrXWorld * newScale;
        let deltaX = (ptrXScaledNew - ptrXScaledOld) / oldScale;

        let ptrYWorld = ptrY + prevCamera.y;
        let ptrYScaledOld = ptrYWorld * oldScale;
        let ptrYScaledNew = ptrYWorld * newScale;
        let deltaY = (ptrYScaledNew - ptrYScaledOld) / oldScale;

        let newX = prevCamera.x + deltaX;
        let newY = prevCamera.y + deltaY;

        return {
          x: newX,
          y: newY,
          scale: newScale,
        };
      });
    };

    const handlePointerDown = (evt) => {
      setDragging(true);
      setPanning(false);
      setDragCursorStartPos({ x: evt.screenX, y: evt.screenY });
      setDragCameraStartPos({ x: camera.x, y: camera.y });
    };

    const handlePointerUp = (evt) => {
      setDragging(false);
      setPanning(false);
      svgRef.current.releasePointerCapture(evt.pointerId);
    };

    const handlePointerMove = (evt) => {
      if (!dragging) return;

      const screenPos = { x: evt.screenX, y: evt.screenY };

      const dist = distance(screenPos, dragCursorStartPos);

      if (dist > dragThreshold && !panning) {
        setPanning(true);
        svgRef.current.setPointerCapture(evt.pointerId);
      }
      if (panning) {
        const dx = dragCursorStartPos.x - screenPos.x;
        const dy = dragCursorStartPos.y - screenPos.y;

        setCamera({
          x: dragCameraStartPos.x + dx,
          y: dragCameraStartPos.y + dy,
          scale: camera.scale,
        });
      }
    };

    const handleTimescaleChanged = (val) => {
      setTimescale(val);
    };

    const handleEventBoxClicked = (event) => {
      setSelectedEvent(event);
    };

    const handleSelectionOverlayClicked = () => {
      setSelectedEvent(null);
    };

    return (
      <svg
        ref={svgRef}
        style={{
          width,
          height,
          backgroundColor: 'white',
          fontFamily: 'Helvetica',
          lineHeight: '1.16', //TODO: don't hardcode
          color: 'black',
          whiteSpace: 'pre-wrap',
          userSelect: 'none',
          cursor: dragging ? 'grabbing' : 'grab',
          border: '1px solid rgb(191,191,191)', //TODO: don't hardcode
        }}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
      >
        {layout ? (
          <>
            <g transform={`translate(${-camera.x}, ${-camera.y}) scale(${camera.scale})`}>
              {layout.eventBoxes.map((box) => (
                <line
                  key={box.event.id}
                  x1={box.x}
                  y1={box.y}
                  x2={box.x}
                  y2={-10000} // TODO: This is a cheat
                  stroke={selectedEvent ? 'hsla(210, 0%, 50%, 0.5)' : box.color}
                  strokeWidth={1}
                  pointerEvents="none"
                ></line>
              ))}
              {layout.eventBoxes.map((box) => (
                <EventBox
                  key={box.event.id}
                  box={box}
                  faded={selectedEvent && selectedEvent.id !== box.event.id}
                  onClicked={handleEventBoxClicked}
                />
              ))}
            </g>

            <Tape
              length={width}
              thickness={tapeThickness}
              camera={camera}
              layout={layout}
              onTimescaleChanged={handleTimescaleChanged}
            />

            {selectedEvent && (
              <SelectionOverlay width={width} height={height} onClicked={handleSelectionOverlayClicked} />
            )}
          </>
        ) : (
          <g transform={`translate(50, 50)`}>
            <text>No data</text>
          </g>
        )}
      </svg>
    );
  }
);
Timevine.displayName = 'Timevine';
Timevine.propTypes = Timevine_propTypes;
export default Timevine;

//-------------------------------------------------------------------------------------------------
// <SelectionOverlay> COMPONENT
//-------------------------------------------------------------------------------------------------
const SelectionOverlay_propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onClicked: PropTypes.func.isRequired,
};
function SelectionOverlay({ width, height, onClicked }) {
  const [down, setDown] = useState(false);

  const handlePointerDown = (event) => {
    event.stopPropagation();
    setDown(true);
  };
  const handlePointerUp = (event) => {
    event.stopPropagation();
    if (down) {
      onClicked();
    }
    setDown(false);
  };
  return (
    <rect
      width={width}
      height={height}
      style={{ fill: 'rgba(0,0,0,0.0)', cursor: 'pointer' }}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
    ></rect>
  );
}
SelectionOverlay.propTypes = SelectionOverlay_propTypes;

//-------------------------------------------------------------------------------------------------
// <Tape> COMPONENT
//-------------------------------------------------------------------------------------------------
const Tape_propTypes = {
  length: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired,
  camera: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  onTimescaleChanged: PropTypes.func.isRequired,
};
function Tape({ length, thickness, camera, layout, onTimescaleChanged }) {
  const [dragging, setDragging] = useState(false);
  const [dragCursorStartPos, setDragCursorStartPos] = useState({ x: 0, y: 0 });
  const [dragTimescaleStart, setDragTimescaleStart] = useState(0.0);

  const rectRef = useRef();

  const tapeLayout = buildTapeLayout({ layout, camera, length });

  const finishDrag = (event) => {
    if (dragging) {
      const delta = 1 + (event.screenX - dragCursorStartPos.x) / 100;
      let newTimescale = dragTimescaleStart * delta;
      newTimescale = clamp(newTimescale, 0.1, 400);
      onTimescaleChanged(newTimescale);
    }
  };

  const handlePointerDown = (event) => {
    event.stopPropagation();
    rectRef.current.setPointerCapture(event.pointerId);
    setDragging(true);
    setDragCursorStartPos({ x: event.screenX, y: event.screenY });
    setDragTimescaleStart(layout.timescale);
  };
  const handlePointerUp = (event) => {
    event.stopPropagation();
    rectRef.current.releasePointerCapture(event.pointerId);
    finishDrag(event);
    setDragging(false);
  };
  const handlePointerMove = (event) => {
    event.stopPropagation();
    finishDrag(event);
  };

  return (
    <g transform={`translate(0, 0)`}>
      <rect width={length} height={thickness} style={{ fill: '#fff' }}></rect>

      <line
        x1={0}
        x2={length}
        y1={thickness}
        y2={thickness}
        stroke="gray"
        strokeWidth={1}
        pointerEvents={'none'}
      ></line>

      {tapeLayout.leftLabel.visible && (
        <g transform={`translate(${tapeLayout.padding.dateLabelX}, ${tapeLayout.padding.dateLabelY})`}>
          <Text text={tapeLayout.leftLabel.text} fontSize={12} color="gray" />
        </g>
      )}

      <g transform={`translate(${-camera.x}, 0)`}>
        {tapeLayout.ticks.map((tick) => (
          <g key={`${tick.label}-${tick.xPos}`}>
            <line
              x1={tick.xPos}
              x2={tick.xPos}
              y1={thickness / 2}
              y2={thickness}
              stroke="gray"
              strokeWidth={1}
              pointerEvents={'none'}
            ></line>
            {/* Don't hardcode these Y offsets for these labels here! */}
            <g transform={`translate(${tick.xPos + tapeLayout.padding.tickLabelX}, 45)`}>
              <text style={{ fontSize: '12px' }} fill="gray">
                {tick.label}
              </text>
            </g>
            {tick.topLabel && (
              <g transform={`translate(${tick.xPos + tapeLayout.padding.tickLabelX}, 14)`}>
                <text style={{ fontSize: '12px' }} fill="gray">
                  {tick.topLabel}
                </text>
              </g>
            )}
          </g>
        ))}
      </g>

      <rect
        ref={rectRef}
        width={length}
        height={thickness}
        style={{ fill: 'transparent', cursor: 'ew-resize' }}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
      ></rect>
    </g>
  );
}
Tape.propTypes = Tape_propTypes;

//-------------------------------------------------------------------------------------------------
// LOCAL HELPER FUNCTIONS
//-------------------------------------------------------------------------------------------------
function clampMin(value, min) {
  return value < min ? min : value;
}
function clampMax(value, max) {
  return value > max ? max : value;
}
function clamp(value, min, max) {
  return clampMax(clampMin(value, min), max);
}

function distance(pt1, pt2) {
  return Math.sqrt(Math.pow(pt2.x - pt1.x, 2) + Math.pow(pt2.y - pt1.y, 2));
}

async function createFontHelvetica() {
  const pdfDoc = await PDFDocument.create();
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  return font;
}
